import CryptoJS from 'crypto-js'

export function useSetupApp() {
  const messages = [
    `Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`, // chromium based
    `NotFoundError: The object can not be found here.`, // safari
  ]
  if (typeof window !== 'undefined') {
    window.addEventListener('error', (ev) => {
      if (messages.includes(ev.message)) {
        ev.preventDefault()
        window.location.reload()
      }
    })
  }
}

export function decrypt(data: any, hashCodeInterview: string) {
  const bytes = CryptoJS.AES.decrypt(data, hashCodeInterview)

  const original = bytes.toString(CryptoJS.enc.Utf8)

  return JSON.parse(original)
}
