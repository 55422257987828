export enum ERole {
  Member = 'MEMBER',
  Gold = 'GOLD',
  Silver = 'SILVER',
  Diamond = 'DIAMOND',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
}

export enum ETopicSlug {
  StudySet = 'study-set',
}

export enum ETypeCard {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum EThemColor {
  Dark = 'dark',
  Light = 'light',
  System = 'system',
  Sepia = 'sepia',
}

export enum EQuestionType {
  boolean = 'boolean',
  multipleChoice = 'multiple-choice',
  written = 'written',
}

export enum ELanguage {
  Vi = 'vi',
  En = 'en',
}

export enum ETopicType {
  Quiz = 'quiz',
  StudySet = 'study-set',
  PostTopic = 'post-topic',
}

export enum EPostTabs {
  New = 'tab_new',
  Following = 'tab_following',
  Bookmark = 'tab_bookmark',
}

export enum EFollowUser {
  Follow = 'FOLLOW',
  UnFollow = 'UNFOLLOW',
}

export enum EMarket {
  Global = 'global',
  VietNam = 'vietnam',
  Fpt = 'fpt',
}
