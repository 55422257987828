import revive_payload_client_4sVQNw7RlN from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_wy7kaSodEX from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/modules/i18n/runtime/plugin.ts";
import components_plugin_KR1HBZs4kY from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import slideovers_LDumGYo2KH from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_OVoKJro5pc from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import _01_initServices_Vy1AL6PEO8 from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/plugins/01.initServices.ts";
import _02_sentry_client_sABltX8K3S from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/plugins/02.sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  plugin_wy7kaSodEX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_OVoKJro5pc,
  _01_initServices_Vy1AL6PEO8,
  _02_sentry_client_sABltX8K3S
]