import type {
  TGetMeResponse,
  TGetRandomUserPremiumResponse,
  TGetRandomUsersResponse,
  TPostChangePasswordPayload,
  TPostUpdateProfilePayload,
  TUserDetail,
  TUserInfoResponse,
} from './user.types'

export async function getMe() {
  const data = await makeRequest<TGetMeResponse>('/api/users/me')
  return data
}

export async function changePassword(payload: TPostChangePasswordPayload) {
  const data = await makeRequest<TGetMeResponse>('/api/users/change-password', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function updateProfile(payload: TPostUpdateProfilePayload) {
  const data = await makeRequest<TGetMeResponse>('/api/users/update-profile', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function updateAvatar(payload: FormData) {
  const data = await makeRequest<TGetMeResponse>('/api/users/update-avatar', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function getInfoByUsername(username: string) {
  const data = await makeRequest<TUserInfoResponse>(
    `/api/users/user/${username}`,
  )
  return data
}

export async function getUserDetail(id: string) {
  const data = await makeRequest<TUserDetail>(`/api/users/${id}`, {
    method: 'GET',
  })
  return data
}

export async function getRandomUsers() {
  const data = await makeRequest<TGetRandomUsersResponse>(
    '/api/users/random-users',
    {
      method: 'GET',
    },
  )
  return data
}
