import { CookieService } from '@techmely/utils'

export default defineNuxtPlugin({
  name: 'Init Services',
  parallel: true,
  setup(nuxt) {
    const config = useRuntimeConfig()

    const cookieService = new CookieService(
      config.public.nodeEnv as any,
      config.public.env,
      config.public.cookieDomain,
    )
    nuxt.provide('cookieService', cookieService)
  },
})
