<template>
  <UCard class="mt-6">
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-3">
        <div class="component">
          <ul class="align">
            <li>
              <figure class="book">
                <ul class="hardcover_front">
                  <li>
                    <div
                      :style="{
                        backgroundImage: `linear-gradient(${colors.background1},${colors.background2})`,
                      }"
                      class="coverDesign"
                    >
                      <h1>{{ bookTitle }}</h1>
                      <p>{{ bookDescription }}</p>
                    </div>
                  </li>
                  <li></li>
                </ul>
                <ul class="page">
                  <li></li>
                  <li>
                    <nuxt-link class="btn" :to="link">{{ bookContent }}</nuxt-link>
                  </li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                <ul class="hardcover_back">
                  <li></li>
                  <li></li>
                </ul>
                <ul class="book_spine">
                  <li></li>
                  <li></li>
                </ul>
              </figure>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-span-12 md:col-span-9 pl-10 flex items-center">
        <div class="mb-8">
          <nuxt-link :to="link">
            <h1 class="text-4xl font-bold text-teal-500">
              {{ title }}
            </h1>
          </nuxt-link>
          <p class="mt-4 text-gray-700 dark:text-gray-300">
            {{ description }}
          </p>
          <div class="grid grid-cols-12 mt-4">
            <div
              v-for="chapter in chapters"
              :key="chapter.id"
              class="col-span-12 md:col-span-6"
            >
              <UButton
                :to="`/bai-viet/${slug}/${chapter.slug}`"
                block
                :padded="false"
                color="gray"
                variant="link"
                class="py-1"
                style="justify-content: flex-start"
                icon="i-heroicons-chevron-right"
                >{{ chapter.title }}</UButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </UCard>
</template>

<script>
export default {
  props: {
    bookTitle: {
      type: String,
      default: "",
    },
    bookDescription: {
      type: String,
      default: "",
    },
    bookContent: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    chapters: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Object,
      default: () => {
        return {
          background1: "#38b2ac",
          background2: "#319795",
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn {
  display: inline-block;
  text-transform: uppercase;
  border: 2px solid #2c3e50;
  margin-top: 100px;
  font-size: 0.7em;
  font-weight: 700;
  padding: 0.1em 0.4em;
  text-align: center;
  transition: color 0.3s, border-color 0.3s;
  &:hover {
    border-color: #16a085;
    color: #16a085;
  }
}

.align {
  clear: both;
  margin: 0;
  width: 100%;
  max-width: 1170px;
  text-align: center;
}

.align > li {
  width: 100%;
  min-height: 300px;
  display: inline-block;
  margin: 30px 20px 30px 30px;
  vertical-align: top;
  /* padding-left: 90px; */
}

.book {
  position: relative;
  width: 180px;
  height: 250px;
  perspective: 1000px;
  transform-style: preserve-3d;
  margin-left: 18px;
}

/*
	2. background & color
*/

/* HARDCOVER FRONT */
.hardcover_front li:first-child {
  background-color: #eee;
  backface-visibility: hidden;
}

/* reverse */
.hardcover_front li:last-child {
  background: #fffbec;
}

/* HARDCOVER BACK */
.hardcover_back li:first-child {
  background: #fffbec;
}

/* reverse */
.hardcover_back li:last-child {
  background: #fffbec;
}

.book_spine li:first-child {
  background: #eee;
}
.book_spine li:last-child {
  background: #333;
}

.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before,
.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before,
.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before,
.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before,
.book_spine li:first-child:after,
.book_spine li:first-child:before,
.book_spine li:last-child:after,
.book_spine li:last-child:before {
  background: #999;
}

/* page */

.page > li {
  background: linear-gradient(#fffbec 0%, #fffbf6 100%);
  box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.1),
    inset -1px 0px 1px rgba(150, 150, 150, 0.2);
  border-radius: 0px 5px 5px 0px;
}

/*
	3. opening cover, back cover and pages
*/

.hardcover_front {
  transform: rotateY(-34deg) translateZ(8px);
  z-index: 100;
}

.hardcover_back {
  transform: rotateY(-15deg) translateZ(-8px);
}

.page li:nth-child(1) {
  transform: rotateY(-28deg);
}

.page li:nth-child(2) {
  transform: rotateY(-30deg);
}

.page li:nth-child(3) {
  transform: rotateY(-32deg);
}

.page li:nth-child(4) {
  transform: rotateY(-34deg);
}

.page li:nth-child(5) {
  transform: rotateY(-36deg);
}

/*
	4. position, transform & transition
*/

.hardcover_front,
.hardcover_back,
.book_spine,
.hardcover_front li,
.hardcover_back li,
.book_spine li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transform-style: preserve-3d;
}

.hardcover_front,
.hardcover_back {
  transform-origin: 0% 100%;
}

.hardcover_front {
  transition: all 0.8s ease, z-index 0.6s;
}

/* HARDCOVER front */
.hardcover_front li:first-child {
  cursor: default;

  user-select: none;

  transform: translateZ(2px);
}

.hardcover_front li:last-child {
  transform: rotateY(180deg) translateZ(2px);
}

/* HARDCOVER back */
.hardcover_back li:first-child {
  transform: translateZ(2px);
}

.hardcover_back li:last-child {
  transform: translateZ(-2px);
}

/* thickness of cover */
.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before,
.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before,
.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before,
.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before,
.book_spine li:first-child:after,
.book_spine li:first-child:before,
.book_spine li:last-child:after,
.book_spine li:last-child:before {
  position: absolute;
  top: 0;
  left: 0;
}

/* HARDCOVER front */
.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before {
  width: 4px;
  height: 100%;
}

.hardcover_front li:first-child:after {
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}

.hardcover_front li:first-child:before {
  transform: rotateY(90deg) translateZ(158px) translateX(2px);
}

.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before {
  width: 4px;
  height: 160px;
}

.hardcover_front li:last-child:after {
  transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px)
    translateY(-78px);
}
.hardcover_front li:last-child:before {
  box-shadow: 0px 0px 30px 5px #333;

  transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px)
    translateY(-78px);
}

/* thickness of cover */

.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before {
  width: 4px;
  height: 100%;
}

.hardcover_back li:first-child:after {
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}
.hardcover_back li:first-child:before {
  transform: rotateY(90deg) translateZ(158px) translateX(2px);
}

.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before {
  width: 4px;
  height: 160px;
}

.hardcover_back li:last-child:after {
  transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px)
    translateY(-78px);
}

.hardcover_back li:last-child:before {
  box-shadow: 10px -1px 80px 20px #666;

  transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px)
    translateY(-78px);
}

/* BOOK SPINE */
.book_spine {
  transform: rotateY(60deg) translateX(-5px) translateZ(-12px);
  width: 16px;
  z-index: 0;
}

.book_spine li:first-child {
  transform: translateZ(2px);
}

.book_spine li:last-child {
  transform: translateZ(-2px);
}

/* thickness of book spine */
.book_spine li:first-child:after,
.book_spine li:first-child:before {
  width: 4px;
  height: 100%;
}

.book_spine li:first-child:after {
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}

.book_spine li:first-child:before {
  transform: rotateY(-90deg) translateZ(-12px);
}

.book_spine li:last-child:after,
.book_spine li:last-child:before {
  width: 4px;
  height: 16px;
}

.book_spine li:last-child:after {
  transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px)
    translateY(-6px);
}

.book_spine li:last-child:before {
  box-shadow: 5px -1px 100px 40px rgba(0, 0, 0, 0.2);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px)
    translateY(-6px);
}

.page,
.page > li {
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
}

.page {
  width: 100%;
  height: 98%;
  top: 1%;
  left: 3%;
  z-index: 10;
}

.page > li {
  width: 100%;
  height: 100%;
  transform-origin: left center;

  transition-property: transform;

  transition-timing-function: ease;
}

.page > li:nth-child(1) {
  transition-duration: 0.6s;
}

.page > li:nth-child(2) {
  transition-duration: 0.6s;
}

.page > li:nth-child(3) {
  transition-duration: 0.4s;
}

.page > li:nth-child(4) {
  transition-duration: 0.5s;
}

.page > li:nth-child(5) {
  transition-duration: 0.6s;
}

/*
	5. events
*/

.book:hover > .hardcover_front {
  transform: rotateY(-145deg) translateZ(0);
  z-index: 0;
}

.book:hover > .page li:nth-child(1) {
  transform: rotateY(-30deg);

  transition-duration: 1.5s;
}

.book:hover > .page li:nth-child(2) {
  transform: rotateY(-35deg);

  transition-duration: 1.8s;
}

.book:hover > .page li:nth-child(3) {
  transform: rotateY(-118deg);

  transition-duration: 1.6s;
}

.book:hover > .page li:nth-child(4) {
  transform: rotateY(-130deg);

  transition-duration: 1.4s;
}

.book:hover > .page li:nth-child(5) {
  transform: rotateY(-140deg);

  transition-duration: 1.2s;
}

/*
	6. Bonus
*/

/* cover CSS */

.coverDesign {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  backface-visibility: hidden;
}

.coverDesign::after {
  background-image: linear-gradient(
    -135deg,
    rgba(255, 255, 255, 0.45) 0%,
    transparent 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.coverDesign h1 {
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  text-align: center;
  line-height: 2.25rem;
  font-weight: bold;
  margin: 30% 0 0 0;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
}

.coverDesign p {
  color: #f8f8f8;
  font-size: 1em;
  text-align: center;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
}

.amber {
  background-color: #f1c40f;
  background-image: linear-gradient(to top, #f1c40f 58%, #e7ba07 0%);
}

.grey {
  background-color: #f8e9d1;
  background-image: linear-gradient(to top, #f8e9d1 58%, #e7d5b7 0%);
}

/* Basic ribbon */

.ribbon {
  background: #c0392b;
  color: #fff;
  display: block;
  font-size: 0.7em;
  position: absolute;
  top: 11px;
  right: 1px;
  width: 40px;
  height: 20px;
  line-height: 20px;
  letter-spacing: 0.15em;
  text-align: center;
  transform: rotateZ(45deg) translateZ(1px);
  backface-visibility: hidden;
  z-index: 10;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  top: -20px;
  width: 0;
  height: 0;
  border-bottom: 20px solid #c0392b;
  border-top: 20px solid transparent;
}

.ribbon::before {
  left: -20px;
  border-left: 20px solid transparent;
}

.ribbon::after {
  right: -20px;
  border-right: 20px solid transparent;
}

/* figcaption */

figcaption {
  padding-left: 40px;
  text-align: left;
  position: absolute;
  top: 0%;
  left: 160px;
  width: 100%;
}

figcaption h1 {
  margin: 0;
}

/* Media Queries */
@media screen and (max-width: 37.8125em) {
  .align > li {
    width: 100%;
    min-height: 440px;
    height: auto;
    padding: 0;
    margin: 0 0 30px 0;

    @media (max-width: 768px) {
      min-height: 270px;
    }
  }

  .book {
    margin: 0 auto;
  }

  figcaption {
    text-align: center;
    width: 320px;
    top: 250px;
    padding-left: 0;
    left: -80px;
    font-size: 90%;
  }
}
</style>
