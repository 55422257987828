import type { UseFetchOptions } from 'nuxt/app'

export const makeRequest = async <TData, TTransform = TData>(
  path: string,
  options?: UseFetchOptions<TData, TTransform>,
) => {
  const runtimeConfig = useRuntimeConfig()
  const nuxtApp = useNuxtApp()
  const baseURL = runtimeConfig.public.apiBase
  const cookieService = nuxtApp.$cookieService
  const token = cookieService.getToken()
  const tokenServer = useCookie(cookieService.tokenName)
  const toast = useToast()
  const cookieLocale = useCookie('locale')
  const cookieMarket = useCookie('market')

  function onRequest({ request, options }) {
    options.baseURL = baseURL
    options.headers = {
      ...options.headers,
      language: cookieLocale.value || 'vi',
      market: cookieMarket.value || 'vietnam',
    }

    if (process.server) {
      if (tokenServer.value) {
        options.headers.Authorization = `Bearer ${tokenServer.value}`
      }
    } else if (process.client) {
      if (token) {
        options.headers.Authorization = `Bearer ${token}`
      }
    }
  }

  function onRequestError({ request, options, error }) {
    // Handle the request errors
  }

  function onResponse({ request, response, options }) {
    // Process the response data
    // localStorage.setItem('token', response._data.token)
  }

  function onResponseError({ request, response, options }) {
    if (response._data)
      toast.add({
        color: 'red',
        description: response._data.message,
      })
    return response
  }

  const value = await useFetch(path, {
    onRequest,
    onRequestError,
    onResponse,
    onResponseError,
    cache: 'default',
    ...options,
  })

  return value
}
