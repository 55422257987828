import { defineNuxtPlugin } from 'nuxt/app'
import { parseCookie } from '@techmely/utils/cookie'
import { loadLocaleAsync } from '~/locales/i18n-util.async'
import { baseLocale } from '~/locales/i18n-util'
import { i18nPlugin } from '~/locales/i18n-vue'
import { Locales } from '~/locales/i18n-types'

export default defineNuxtPlugin(async ({ vueApp, ssrContext }) => {
  const cookie = process.client
    ? document.cookie
    : ssrContext?.event.node.req.headers.cookie || ''
  const parsedCookie = parseCookie(cookie)

  const locale = (parsedCookie?.locale as Locales) || baseLocale
  await loadLocaleAsync(locale)
  vueApp.use(i18nPlugin, locale)
})
